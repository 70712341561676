
export const contentTypeUuidToText = {
    '6A736F6E-0011-0010-8000-00AA00389B71': 'JSON Content type JUMBF box',
    '6579D6FB-DBA2-446B-B2AC-1B82FEEB89D1': 'Codestream Content type JUMBF box',
    '63626F72-0011-0010-8000-00AA00389B71': 'CBOR Content type JUMBF box',
    '75756964-0011-0010-8000-00AA00389B71': 'UUID Content type JUMBF box',
    '786D6C20-0011-0010-8000-00AA00389B71': 'XML Content type JUMBF box',
    '40CB0C32-BB8A-489D-A70B-2AD6F47F4369': 'Embedded File Content type JUMBF box',
    'DC28B95F-B68A-498E-8064-0FCA845D6B0E': 'Replacement Content type JUMBF box',
    '74B11BBF-F21D-4EEA-98C1-0BEBF23AEFD3': 'Protection Content type JUMBF box',
    '4C494E4B-0011-0010-8000-00AA00389B71': 'JLINK Content type JUMBF box',
    '16AD91E0-A37F-11EB-9D0D-0800200C9A66': 'JPEG Snack Content type JUMBF box',
    '785F34B7-5D4B-474C-B89F-1D99E0E3A8DD': 'JPEG 360 Content type JUMBF box',
    '63327061-0011-0010-8000-00AA00389B71': 'JPEG Trust Record',
    '6332756D-0011-0010-8000-00AA00389B71': 'JPEG Trust Update Manifest',
    '63326D61-0011-0010-8000-00AA00389B71': 'JPEG Trust Standard Manifest',
    '63326D64-0011-0010-8000-00AA00389B71': 'JPEG Trust Declaration Manifest',
    '6332636D-0011-0010-8000-00AA00389B71': 'JPEG Trust Compressed Manifest',
    '63327663-0011-0010-8000-00AA00389B71': 'JPEG Trust Credential Store',
    '63326373-0011-0010-8000-00AA00389B71': 'JPEG Trust Claim Signature',
    '6332636C-0011-0010-8000-00AA00389B71': 'JPEG Trust Claim',
    '63326173-0011-0010-8000-00AA00389B71': 'JPEG Trust Assertion Store'
}

export const bmffBoxTypeToText = {
    'jumb': 'JUMBF box',
    'jumd': 'Description box',
    'json': 'JSON box',
    'xml': 'XML box',
    'jp2c': 'Codestream box',
    'bfdb': 'Embedded File Description box',
    'bidb': 'Binary Data box',
    'cbor': 'CBOR box',
    'uuid': 'UUID box',
    'pspd': 'Protection Description box',
    'psrd': 'Replacement Description box',
    'free': 'Padding box',
    'jsdb': 'JPEG Snack Description box',
    'inst': 'Instruction Set box',
    'obmb': 'Object Metadata box',
    'c2sh': 'Salt Hash box'
}