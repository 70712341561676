import { AppBar, Avatar, Box, Container, IconButton, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';

const TitleWrapper = styled('div')(({ theme }) => ({
    backgroundColor: 'aliceblue',
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    height: '10vh',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'black'
}));


const Title = () => {
    return (
        <AppBar position="static" sx={{ backgroundColor: 'rgb(41, 82, 100)' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                    <Typography
                        variant="h4"
                        noWrap
                        component="a"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        MIPAMS JUMBF
                    </Typography>

                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton sx={{ p: 0 }} onClick={() => window.location.reload(false)}>
                            <img src={'./upc.png'} alt="UPC Logo" style={{ maxWidth: '64px' }} />
                        </IconButton>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    )
}

export default Title