import React from 'react'

import Parse from '../containers/Parse';

import Title from './Title';
import { Grid } from '@mui/material';

export default function Layout() {
    return (
        <div>
            <Title />
            <Grid
                container
                spacing={4}
                justifyContent="center"
                sx={{
                    marginTop: 0,
                    height: '85vh',
                    '& .MuiGrid-item': {
                        paddingTop: 0
                    }
                }}
            >
                <Grid item xs={6}>
                    <Parse />
                </Grid>
            </Grid>
        </div>
    )
}